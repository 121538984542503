












import { ambiente } from '@/shareds/utils'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BannerDeAmbiente extends Vue{
	ambiente = ambiente
}

