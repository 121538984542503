


























import { Vue, Component } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import { Plugin } from '@/models'
import printPdfMake from '@/shareds/print/printPdfMake'
import { ReimpressaoModule } from '@/store/vuex/reimpressao/ReimpressaoStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component
export default class DialogoDePlugin extends Vue {
	PluginModule = PluginModule

	dialogFechado(plugin: Plugin) {
		plugin.postMessage('dialog-close')
	}

	created() {
		PluginModule.on('error', (error: Error) => {
			AlertModule.setError(error.message)
		})
		PluginModule.on('success', (mensagem: string) => {
			AlertModule.setSuccess(mensagem)
		})
		PluginModule.on('warning', (mensagem: string) => {
			AlertModule.setWarning(mensagem)
		})
		PluginModule.on('print-pdf-make', ({ titulo, docDefinition }) => {
			printPdfMake(docDefinition)
			ReimpressaoModule.addReimpressao({ titulo, docDefinition })
		})
		PluginModule.on('get-token', () => {
			PluginModule.emit('set-token', { token: UserLoginStore.token })
		})
	}
}
