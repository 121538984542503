




















import { Component, Vue } from 'vue-property-decorator'
import AppStore from '@/store/vuex/aplicacao/AppStore'

@Component({})
export default class BotaoDeAtualizar extends Vue {
	timeout = -1
	dispensado = false

	get visivel() {
		return !this.dispensado && AppStore.requerAtualizacao
	}

	set visivel(visivel: boolean) {
		this.dispensado = !visivel
	}

	atualizar() {
		window.location.reload()
	}
}
