





























































import { Vue, Component, Ref } from 'vue-property-decorator'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { isVestcasa } from '@/shareds/utils';
import Confirmacao from '@/components/ui/Confirmacao.vue'
import ItensDaListaDeMenu from '@/components/ui/ItensDaListaDeMenu.vue';
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component({
	components: {
		ItensDaListaDeMenu,
		Confirmacao,
	},
})
export default class ApplicationSideBar extends Vue  {
	@Ref() confirmacaoDeDescartarVenda!: Confirmacao
	logo = !isVestcasa
		? require('@/assets/almode_256x181.png')
		: require('@/assets/logo-vestcasa.webp')

	miniLogo = !isVestcasa
		? require('@/assets/almode-64x64.png')
		: require('@/assets/v-sem-fundo-vestcasa.png')

	miniVariant: boolean | null = true
	selecionado: null | number = null

	get mostraNavBar() {
		return AppStore.showSideBar
	}

	set mostraNavBar(valor: typeof AppStore.showSideBar) {
		AppStore.setShowSideBar(valor)
	}

	get itens() {
		return AppStore.itensDeMenuPermitidos
	}

	async logout() {
		UserLoginStore.logout()
	}

	deslogarComVendaEmAndamento() {
		VendaModule.temVendaEmAndamento ? this.confirmacaoDeDescartarVenda.mostrar() : UserLoginStore.logout()
	}

	get mostrarContagem() {
		return UserLoginStore.mostrarContagem
	}
}

