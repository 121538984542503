






































import { Vue, Component, Ref } from 'vue-property-decorator'
import AppStore from '@/store/vuex/aplicacao/AppStore'

@Component
export default class SumarioDeTeclasDeAtalho extends Vue {
	@Ref() botaoDeFechar!: { $el: HTMLButtonElement }

	get mostra() {
		return AppStore.mostraSumarioDeTeclas
	}

	set mostra(mostra: typeof AppStore.showSideBar) {
		AppStore.setMostraSumarioDeTeclas(mostra)
	}

	grupoDeAtalhos = [
		{
			titulo: 'Básico',
			atalhos: [
				{
					descricao: 'Mostrar/Esconde este Modal',
					keys: [ 'Alt', '/' ],
				},
				{
					descricao: 'Voltar / Sair',
					keys: [ 'Esc' ],
				},
				{
					descricao: 'Focar próximo item',
					keys: [ 'Tab' ],
				},
				{
					descricao: 'Focar item anterior',
					keys: [ 'Shift', 'Tab' ],
				},
			],
		},
		{
			titulo: 'Tela de Vendas / Orçamentos',
			atalhos: [
				{
					descricao: 'Abrir/Fechar PDV',
					keys: [ 'Alt', 'A' ],
				},
				{
					descricao: 'Selecionar produto',
					keys: [ 'Alt', 'S' ],
				},
				{
					descricao: 'Selecionar cliente',
					keys: [ 'Alt', 'C' ],
				},
				{
					descricao: 'Pagar / Devolver',
					keys: [ 'Alt', 'R' ],
				},
				{
					descricao: 'Alterar último item incluso',
					keys: [ 'Alt', 'U' ],
				},
				{
					descricao: 'Abrir orçamento',
					keys: [ 'Alt', 'O' ],
				},
				{
					descricao: 'Abrir desconto da capa da venda',
					keys: [ 'Alt', 'D' ],
				},
				{
					descricao: 'Abrir desconto em grupo',
					keys: [ 'Alt', 'Z' ],
				},
			],
		},
		{
			titulo: 'Pagamento',
			atalhos: [
				{
					descricao: 'Selecionar valor',
					keys: [ 'Alt', 'v' ],
				},
				{
					descricao: 'Usar tipo de pagamento específico (1-9)',
					keys: [ 'Alt', '1-9' ],
				},
			],
		},
	]

	created() {
		document.addEventListener('keydown', this.atalhoSumarioDeTeclas)
	}

	destroyed() {
		document.removeEventListener('keydown', this.atalhoSumarioDeTeclas)
	}

	atalhoSumarioDeTeclas(event: KeyboardEvent) {
		if (event.altKey && event.key === '/') {
			this.mostra = !this.mostra
			setTimeout(() => {
				this.botaoDeFechar.$el.focus()
			})
		}
		if (event.key === 'Escape') {
			this.mostra = false
		}
	}
}

