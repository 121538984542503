


























import { Vue, Component, Ref } from 'vue-property-decorator'
import ApplicationSideBar from '@/components/layout/ApplicationSideBar.vue'
import AppAlert from '@/components/layout/AppAlert.vue'
import SumarioDeTeclasDeAtalho from '@/components/ui/SumarioDeTeclasDeAtalho.vue'
import DialogoDePlugin from '@/components/plugin/DialogoDePlugin.vue'
import { Fragment } from 'vue-fragment'
import AppStore from '../../store/vuex/aplicacao/AppStore'
import BannerDeAmbiente from '@/components/ui/BannerDeAmbiente.vue'
import BotaoDeAtualizar from '@/components/ui/BotaoDeAtualizar.vue'
import { ambiente } from '@/shareds/utils'
import { tempoDeInatividadeInstance } from '@/models/TempoDeInatividade'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import DialogoDeAutenticacao from './venda/DialogoDeAutenticacao.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		ApplicationSideBar,
		AppAlert,
		Fragment,
		SumarioDeTeclasDeAtalho,
		DialogoDePlugin,
		BannerDeAmbiente,
		BotaoDeAtualizar,
		DialogoDeAutenticacao,
	},
})
export default class Application extends Vue {
	version = process.env.PACKAGE_VERSION || ''
	AppStore = AppStore
	ambiente = ambiente
	@Ref() dialogoDeAutenticacao!: DialogoDeAutenticacao
	
	mounted() {
		tempoDeInatividadeInstance.adicionaEventListeners()
		VendaModule.setDialogDeAutenticacao(this.dialogoDeAutenticacao)
		UserLoginStore.setTempoLimiteDeInatividade(UserLoginStore.tempoLimite)
		tempoDeInatividadeInstance.estaAutenticando()
	}

	get ocultar() {
		return UserLoginStore.autenticando
	}
}
