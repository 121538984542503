





































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ItemDeMenu } from '@/shareds/itensDeMenu'
import AppStore from '@/store/vuex/aplicacao/AppStore'
import PedidoStore from '@/store/vuex/aplicacao/PedidoStore'

@Component
export default class ItensDaListaDeMenu extends Vue {
	@Prop({ type: Array, default: () => [] }) itens!: ItemDeMenu[]

	temPermissaoItemDeMenu = AppStore.itemDeMenuEstaPermitido

	obterCorDoItem(item: ItemDeMenu) {
		return this.temRotasAtivaDoItem(item)
			? 'primary'
			: (AppStore.modoNoturno ? 'white' : 'grey darken-2')
	}

	temRotasAtivaDoItem(item: ItemDeMenu) {
		return (
			item.route &&
			typeof item.route !== 'string' &&
			this.$route.name === item.route.name
		) || (
			item.children &&
			item.children.some(this.temRotasAtivaDoItem)
		)
	}
	
	get mostraNavBar() {
		return AppStore.showSideBar
	}

	get pedidos() {
		return PedidoStore.pedidos
	}
}

