import UserLoginStore from "@/store/vuex/authentication/UserLoginStore";
import { VendaModule } from "@/store/vuex/venda/VendaStore";
import { horaParaSegundos } from "@/views/application/contador/ContagemRegressiva.vue";

export default class TempoDeInatividade {
	private contador = 0;
	private intervalId: NodeJS.Timeout | null = null;
	public permissaoConcedida: boolean | undefined = false

	public estaAutenticando() {
		if(UserLoginStore.autenticando) {
			this.autenticar()
		}
	}

	private startContador = () => {
		
		this.contador = horaParaSegundos(UserLoginStore.tempoLimite)

		if(!UserLoginStore.autenticando && UserLoginStore.isLoggedIn) {
			
			if (this.intervalId) {
				clearInterval(this.intervalId);
			}
				
			if (this.contador > 0) {
				this.intervalId = setInterval(() => {
					if (this.contador <= 0) {
						this.stopContador();
					} else {
						this.contador--;
					}
				}, 1000);
			}
		}
	}

	private stopContador(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
			this.autenticar()
		}
	}

	private addEventListeners(): void {
		document.addEventListener('mousemove', this.startContador);
		document.addEventListener('keydown', this.startContador);
	}


	public adicionaEventListeners() {
		setTimeout(() => {
			this.addEventListeners();
		}, 1 * 1000)
	}
	
	private async autenticar() {
		UserLoginStore.setAutenticando(true)
		this.permissaoConcedida = await VendaModule.autenticarPorTempoDeInatividade()
		if(this.permissaoConcedida) {
			UserLoginStore.setAutenticando(false)
		}
		
	}  
}
export const tempoDeInatividadeInstance = new TempoDeInatividade();